* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

/* Handle on Hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

section:first-child {
  padding-top: 150px;
}

@keyframes top_to_bottom_animation {
  0% {
    transform: translateY(-142px);
  }

  100% {
    transform: translateY(0px);
  }
}

.top_to_bottom_animation {
  animation-duration: 1s;
  animation-name: top_to_bottom_animation;
}

@keyframes scale_animation {
  0% {
    transform: scale(0, 0.4);
  }

  50% {
    transform: scale(1, 0.4);
  }
}

.scale_animation {
  animation-duration: 2s;
  animation-name: scale_animation;
}

@keyframes bottom_to_top_animation {
  0% {
    transform: translateY(142px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.bottom_to_top_animation {
  animation-duration: 2s;
  animation-name: bottom_to_top_animation;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
    transform: scale(.1);
  }

  85% {
    opacity: 1;
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.postAnimation div {
  animation: FadeIn 1s linear;
  animation-fill-mode: both;
}

.postAnimation div:nth-child(1) {
  animation-delay: 0.5s
}

.postAnimation div:nth-child(2) {
  animation-delay: 1s
}

.postAnimation div:nth-child(3) {
  animation-delay: 1.5s
}

.postAnimation div:nth-child(4) {
  animation-delay: 2s
}